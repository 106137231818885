<template>
  <div class="w-full relative">
    <div class="flex justify-between gap-4 md:gap-8 items-center px-4">
      <button class="w-8 h-8 p-1.5" @click="toggleMenu">
        <i
          :class="[
            pro ? 'text-grayscale-7' : 'text-grayscale-80',
            'icon-hamburger-menu text-20 leading-5 my-auto flex-shrink-0',
          ]"
        />
      </button>

      <div
        v-if="
          !pastCalls && request.proStatus !== ProStatus.pending && props.pro
        "
        :class="[`${textClass}`, 'w-full']"
      >
        {{ text }}
      </div>
      <div class="flex gap-4 items-center">
        <button
          v-if="!pastCalls && longList"
          :disabled="!callTime || proPending"
          :class="[
            joinButtonStyle
              ? 'bg-accent-orange text-grayscale-7 border border-grayscale-7'
              : 'bg-grayscale-7 text-grayscale-96',
            'flex px-4 rounded-54 items-center h-8 shrink-0',
          ]"
          @click="$emit('callOptions', 'join')"
        >
          <i
            :class="[
              joinButtonStyle ? 'text-grayscale-7' : 'text-grayscale-96',
              'icon-call2 text-24 leading-6 shrink-0',
            ]"
          />
          <p class="ml-1.5 font-semibold text-sm">Join</p>
        </button>
        <button
          v-if="!pastCalls && pro && ((!callTime && longList) || proPending)"
          class="bg-grayscale-7 text-grayscale-96 flex px-4 rounded-54 items-center h-8 shrink-0"
          @click="openTimeSlotsModal"
        >
          <p class="ml-1.5 font-semibold text-sm">{{ buttonType }}</p>
        </button>
        <p
          v-if="[CallStatus.refunded, CallStatus.declined].includes(callStatus)"
          class="text-grayscale-7 mr-3"
        >
          Cancelled
        </p>
      </div>
    </div>
    <div
      v-if="isOpenMenu"
      :class="[
        pro ? 'bottom-8' : 'top-8',
        'absolute bg-grayscale-10 border border-grayscale-13 rounded-8 px-4 py-3 w-max flex flex-col gap-4',
      ]"
    >
      <div v-for="item in menuList" :key="item.title" class="flex-1">
        <a
          v-if="item.action === 'support'"
          href="mailto:hey@xposuremusic.com"
          class="flex gap-4 pr-2 w-full"
        >
          <i
            :class="`icon-${item.icon} text-grayscale-96 text-20 leading-5 my-auto flex-shrink-0`"
          />
          <p class="text-grayscale-96">{{ item.title }}</p>
        </a>
        <button
          v-else
          class="flex gap-4 pr-2 w-full"
          @click="buttonAction(item.action)"
        >
          <i
            :class="`icon-${item.icon} text-grayscale-96 text-20 leading-5 my-auto flex-shrink-0`"
          />
          <p class="text-grayscale-96">{{ item.title }}</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { CallStatus, Order, ProStatus } from "@/types/order";
import { PropType, computed, onMounted, ref } from "vue";
import {
  getRemainingTimeCall,
  getRemainingMinutes,
  printableTimezone,
} from "@/utils/dates";
import dayjs from "dayjs";

import duration from "dayjs/plugin/duration";
import { useUserStore } from "../../../../store/user";

const router = useRouter();

dayjs.extend(duration);

const props = defineProps({
  request: {
    type: Object as PropType<Order>,
    required: true,
  },
  pro: {
    type: Boolean,
    default: true,
  },
  pastCalls: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "callOptions",
  "openDeclineModal",
  "openRescheduleModal",
]);

const userStore = useUserStore();

const isOpenMenu = ref(false);
const callTime = computed(
  () => props.request.feedbackRequest.call!.callTime?.toString() || "",
);
const proPending = computed(
  () => props.request.proStatus === ProStatus.pending,
);
const remainingMinutes = ref("Loading...");
const remainingHours = computed(() =>
  callTime.value ? getRemainingHours(callTime.value.toString()) : "",
);
const callStatus = computed(() => props.request.callStatus!);
const longList = computed(
  () =>
    ![CallStatus.completed, CallStatus.refunded, CallStatus.declined].includes(
      callStatus.value,
    ),
);
const menuList = computed(() =>
  [
    {
      title: "Reschedule",
      icon: "reschedule",
      show: longList.value && props.pro,
      action: "emit('openRescheduleModal')",
    },
    {
      title: "Chat",
      icon: "comment",
      show: true && props.pro,
      action: "chat",
    },
    {
      title: "Contact support",
      icon: "questionmark",
      show: true,
      action: "support",
    },
    {
      title: "Cancel Call",
      icon: "circle-cross",
      show: longList.value && props.pro,
      action: "emit('openDeclineModal')",
    },
  ].filter((item) => item.show),
);

const getRemainingHours = (time: string) => {
  const end = dayjs(time);
  const startTime = dayjs();
  const duration = dayjs.duration(end.diff(startTime));
  const hours = Math.floor(duration.asHours());

  return hours.toString();
};

const buttonType = computed(() =>
  props.request.feedbackRequest!.call!.requestedTimestamps
    ? "Choose time"
    : "Accept",
);

const joinButtonStyle = computed(
  () => (props.pro && !callTime.value) || (!props.pro && callTime.value),
);

const text = computed(() => getText());
const getText = () => {
  if (!callTime.value || !longList.value) {
    return;
  } else {
    if (callStatus.value === CallStatus.scheduled) {
      return parseInt(remainingHours.value) > 1
        ? getRemainingTimeCall(callTime.value, userStore.userData.timezone)
        : `Starts in ${remainingMinutes.value}`;
    } else if (callStatus.value === CallStatus.incompleted) {
      return `Was scheduled on ${dayjs(callTime.value)
        .tz(userStore.userData.timezone)
        .format("MMM D, LT")} ${printableTimezone(
        userStore.userData.timezone,
      )}`;
    } else if (CallStatus.active === callStatus.value) {
      return `Active Call`;
    }
  }
};
const textClass = computed(() =>
  callStatus.value === CallStatus.active ||
  (parseInt(remainingHours.value) < 1 && parseInt(remainingHours.value) > 0)
    ? "text-accent-green"
    : "text-grayscale-7 text-sm sm:text-base",
);

let timer: any;
onMounted(() => {
  timer = setInterval(() => {
    remainingMinutes.value = callTime.value
      ? getRemainingMinutes(callTime.value)
      : "";
  }, 1000);
});

const toggleMenu = () => {
  isOpenMenu.value = !isOpenMenu.value;
};

const openTimeSlotsModal = () => {
  props.request.feedbackRequest!.call!.requestedTimestamps
    ? emit("callOptions", "choose time")
    : emit("callOptions", "accept");
};

const buttonAction = (action: string) => {
  if (action.includes("emit")) {
    eval(action);
  } else {
    if (action === "chat") {
      router.push(`/chat?user=${props.request.user?._id}`);
    }
  }
};

watch(
  () => props.request,
  () => (isOpenMenu.value = false),
);
</script>
